import React from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import styles from "./AuctionList.module.scss";
import ViewTypes from "../../../../../enums/ViewTypes";
import { useAppDispatch } from "../../../../../store";
import { updateFilters } from "../../../../../store/filters/reducer";
import { getSelectedViewType } from "../../../../../store/filters/selectors";
import {
  selectAvailableMarkets,
  selectInstagramAccountsLoading,
} from "../../../../../store/user/selectors";
import { Market } from "../../../../../types/Market";
import { PathTypes } from "../NewAuctionCreation/Types/PathTypes";
import { setPath } from "../../../../../store/auctionModal/reducer";

const MultipleCardView = ({ onClick, selected }) => (
  <svg
    onClick={onClick}
    width="21"
    height="21"
    viewBox="5 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.875 2.08301H16.6667C16.0665 2.08301 15.625 2.52453 15.625 3.12467V8.33301C15.625 8.93316 16.0665 9.37467 16.6667 9.37467H21.875C22.4751 9.37467 22.9167 8.93316 22.9167 8.33301V3.12467C22.9167 2.52453 22.4751 2.08301 21.875 2.08301ZM16.6668 8.33282V3.12449H21.8752V8.33282H16.6668Z"
      fill={selected ? "#F4799E" : "#606060"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5415 2.08301H8.33317C7.73302 2.08301 7.2915 2.52453 7.2915 3.12467V8.33301C7.2915 8.93316 7.73302 9.37467 8.33317 9.37467H13.5415C14.1417 9.37467 14.5832 8.93316 14.5832 8.33301V3.12467C14.5832 2.52453 14.1417 2.08301 13.5415 2.08301ZM8.33333 8.33282V3.12449H13.5417V8.33282H8.33333Z"
      fill={selected ? "#F4799E" : "#606060"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.875 10.417H16.6667C16.0665 10.417 15.625 10.8585 15.625 11.4587V16.667C15.625 17.2671 16.0665 17.7087 16.6667 17.7087H21.875C22.4751 17.7087 22.9167 17.2671 22.9167 16.667V11.4587C22.9167 10.8585 22.4751 10.417 21.875 10.417ZM16.6668 16.6668V11.4585H21.8752V16.6668H16.6668Z"
      fill={selected ? "#F4799E" : "#606060"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5415 10.417H8.33317C7.73302 10.417 7.2915 10.8585 7.2915 11.4587V16.667C7.2915 17.2671 7.73302 17.7087 8.33317 17.7087H13.5415C14.1417 17.7087 14.5832 17.2671 14.5832 16.667V11.4587C14.5832 10.8585 14.1417 10.417 13.5415 10.417ZM8.33333 16.6668V11.4585H13.5417V16.6668H8.33333Z"
      fill={selected ? "#F4799E" : "#606060"}
    />
  </svg>
);

const ListView = ({ onClick, selected }) => (
  <svg
    onClick={onClick}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4"
      y="3"
      width="17"
      height="2"
      rx="1"
      fill={selected ? "#F4799E" : "#606060"}
    />
    <rect
      x="4"
      y="7"
      width="17"
      height="2"
      rx="1"
      fill={selected ? "#F4799E" : "#606060"}
    />
    <rect
      x="4"
      y="11"
      width="17"
      height="2"
      rx="1"
      fill={selected ? "#F4799E" : "#606060"}
    />
    <rect
      x="4"
      y="15"
      width="17"
      height="2"
      rx="1"
      fill={selected ? "#F4799E" : "#606060"}
    />
  </svg>
);

const AuctionListSearchFilters = () => {
  const dispatch = useAppDispatch();
  const viewType = useSelector((state) => getSelectedViewType(state));
  const availableMarkets = useSelector((state) =>
    selectAvailableMarkets(state)
  ) as Market[];
  const instagramAccountsLoading = useSelector((state) =>
    selectInstagramAccountsLoading(state)
  );

  const updateView = (viewType) => {
    dispatch(updateFilters(viewType));
  };

  const handleAuctionCreation = () => {
    // Add Redux auction create method
    dispatch(setPath(PathTypes.AUCTIONS));
    if (availableMarkets.length === 0) {
      // No markets they need to connect to one

      navigate("/dashboard/getting-started/connect-instagram/", {
        state: {
          fromPath: PathTypes.AUCTIONS,
          originationPath: PathTypes.AUCTIONS,
          toPath: PathTypes.AUCTION_CREATION_MAIN,
        },
      });
    } else if (availableMarkets.length === 1) {
      // Only one market go straight to new auction page
      navigate("/dashboard/auctions/new/", {
        state: {
          fromPath: PathTypes.AUCTIONS,
          originationPath: PathTypes.AUCTIONS,
        },
      });
    } else {
      // Multiple markets go to new auction page (will need to select market)
      navigate("/dashboard/auctions/new/", {
        state: {
          fromPath: PathTypes.AUCTIONS,
          originationPath: PathTypes.AUCTIONS,
        },
      });
    }
  };

  return (
    <div
      className={cn([
        "flex flex-row justify-between items-center mx-4 mb-1 mt-4 border-b border-solid pb-2 border-bidddyGray",
        styles.filters,
      ])}
    >
      <h4>My Auctions</h4>
      <div className={styles.filtersContainer}>
        <button
          type="button"
          disabled={instagramAccountsLoading}
          aria-label="Create Auction"
          onClick={handleAuctionCreation}
          className={styles.addAuction}
        />
        <div className={styles.separator} />
        <MultipleCardView
          selected={viewType === ViewTypes.MultipleCardView}
          onClick={() => updateView(ViewTypes.MultipleCardView)}
        />
        <ListView
          selected={viewType === ViewTypes.ListView}
          onClick={() => updateView(ViewTypes.ListView)}
        />
      </div>
    </div>
  );
};

export default AuctionListSearchFilters;
