import React from "react";

const InstagramGray = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3332 1.6665H6.6665C3.90508 1.6665 1.6665 3.90508 1.6665 6.6665V13.3332C1.6665 16.0946 3.90508 18.3332 6.6665 18.3332H13.3332C16.0946 18.3332 18.3332 16.0946 18.3332 13.3332V6.6665C18.3332 3.90508 16.0946 1.6665 13.3332 1.6665ZM3.33316 6.66651C3.33316 4.82556 4.82555 3.33317 6.6665 3.33317H13.3332C15.1741 3.33317 16.6665 4.82556 16.6665 6.66651V13.3332C16.6665 15.1741 15.1741 16.6665 13.3332 16.6665H6.6665C4.82555 16.6665 3.33316 15.1741 3.33316 13.3332V6.66651ZM9.99983 14.1665C7.69864 14.1665 5.83316 12.301 5.83316 9.99984C5.83316 7.69865 7.69864 5.83317 9.99983 5.83317C12.301 5.83317 14.1665 7.69865 14.1665 9.99984C14.1665 12.301 12.301 14.1665 9.99983 14.1665ZM9.99984 12.4998C11.3806 12.4998 12.4998 11.3805 12.4998 9.99983C12.4998 8.61912 11.3806 7.49983 9.99984 7.49983C8.61913 7.49983 7.49984 8.61912 7.49984 9.99983C7.49984 11.3805 8.61913 12.4998 9.99984 12.4998ZM14.9998 5.83317C14.9998 6.2934 14.6267 6.6665 14.1665 6.6665C13.7063 6.6665 13.3332 6.2934 13.3332 5.83317C13.3332 5.37293 13.7063 4.99983 14.1665 4.99983C14.6267 4.99983 14.9998 5.37293 14.9998 5.83317Z"
      fill="#DBD8DD"
    />
  </svg>
);

export default InstagramGray;
