import React from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { CategoryTypes } from "../../../../enums/CategoryTypes";
import styles from "./AuctionCategorySelectionList.module.scss";
import { useAppDispatch } from "../../../../store";
import { selectProgressFilter } from "../../../../store/auctions/selectors";
import { setProgressFilter } from "../../../../store/auctions/reducer";

const AuctionCategorySelectionList = () => {
  const dispatch = useAppDispatch();
  const newProgress = useSelector((state) => selectProgressFilter(state));

  const filterByProgress = (progress) => {
    dispatch(setProgressFilter(progress));
  };

  return (
    <div className={styles.categoryList}>
      <button
        type="button"
        className={cn({
          [styles.active]:
            newProgress === CategoryTypes.All || newProgress === null,
        })}
        onClick={() => filterByProgress(CategoryTypes.All)}
      >
        All
      </button>
      <button
        type="button"
        className={cn({ [styles.active]: newProgress === CategoryTypes.Live })}
        onClick={() => filterByProgress(CategoryTypes.Live)}
      >
        Live
      </button>
      <button
        type="button"
        className={cn({
          [styles.active]: newProgress === CategoryTypes.Upcoming,
        })}
        onClick={() => filterByProgress(CategoryTypes.Upcoming)}
      >
        Upcoming
      </button>
      <button
        type="button"
        className={cn({
          [styles.active]: newProgress === CategoryTypes.Drafts,
        })}
        onClick={() => filterByProgress(CategoryTypes.Drafts)}
      >
        Drafts
      </button>
      <button
        type="button"
        className={cn({
          [styles.active]: newProgress === CategoryTypes["Awaiting Connection"],
        })}
        onClick={() => filterByProgress(CategoryTypes["Awaiting Connection"])}
      >
        Awaiting Connection
      </button>
      <button
        type="button"
        className={cn({ [styles.active]: newProgress === CategoryTypes.Past })}
        onClick={() => filterByProgress(CategoryTypes.Past)}
      >
        Closed
      </button>
    </div>
  );
};

export default AuctionCategorySelectionList;
