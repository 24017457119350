import React from "react";
import { useSelector } from "react-redux";
import { AuctionResults } from "../../../../../store/auctions/actions";
import { selectAuctions } from "../../../../../store/auctions/selectors";
import styles from "./AuctionList.module.scss";
import AuctionCard from "../Cards/AuctionCard";
import { getSelectedViewType } from "../../../../../store/filters/selectors";
import ViewTypes from "../../../../../enums/ViewTypes";
import { isMobile } from "../../../../../utils/helpers";
import BasicTable from "../../../../Table/BasicTable";
import BasicTableHeader from "../../../../Table/BasicTableHeader";
import BasicTableRow from "../../../../Table/BasicTableRow";
import BasicTableBody from "../../../../Table/BasicTableBody";
import ListItem from "../Cards/ListItem";

const AuctionListWithFilters = () => {
  const viewType = useSelector((state) => getSelectedViewType(state));
  const auctions = useSelector((state) =>
    selectAuctions(state)
  ) as AuctionResults;

  return (
    <div className={styles.auctionContainer}>
      {viewType === ViewTypes.MultipleCardView && (
        <div className={styles.auctionListContainer}>
          {viewType === ViewTypes.MultipleCardView &&
            auctions &&
            auctions.data &&
            auctions.data.length > 0 &&
            auctions.data.map((auction, index) => (
              <AuctionCard
                key={`AuctionCard-${auction.id}`}
                keyVal={`${auction.id}-${index}`}
                auction={auction}
              />
            ))}
        </div>
      )}
      {viewType === ViewTypes.ListView && (
        <div className={styles.auctionListTable}>
          <BasicTable>
            <BasicTableHeader>
              <BasicTableRow>
                <td>Item</td>
                <td>Name</td>
                {!isMobile.any() && <td>End Date</td>}
                <td>{isMobile.any() ? "Highest" : "Highest Bid"}</td>
                <td>Bids</td>
                <td>Status</td>
                <td>{isMobile.any() ? "Code" : "Activation Code"}</td>
              </BasicTableRow>
            </BasicTableHeader>
            <BasicTableBody>
              {viewType === ViewTypes.ListView &&
                auctions &&
                auctions.data &&
                auctions.data.length > 0 &&
                auctions.data.map((auction) => (
                  <ListItem auction={auction} key={`${auction.id}-ListView`} />
                ))}
            </BasicTableBody>
          </BasicTable>
        </div>
      )}
      {auctions && auctions.data && auctions.data.length === 0 && (
        <div className={styles.noAuctions}>
          <p>There are no auctions. Create a new auction above.</p>
        </div>
      )}
    </div>
  );
};

export default AuctionListWithFilters;
