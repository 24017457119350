import React from "react";
import LazyLoad from "react-lazyload";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import cn from "classnames";
import { navigate } from "gatsby";
import Imgix from "react-imgix";
import styles from "./AuctionCard.module.scss";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import { Auction } from "../../../../../types/Auction";
import { getSelectedViewType } from "../../../../../store/filters/selectors";
import ViewTypes from "../../../../../enums/ViewTypes";
import { ScheduledAuction } from "../../../../../store/auctions/actions";
import {
  getAuctionState,
  getCurrencyLocaleValue,
  isMobile,
} from "../../../../../utils/helpers";
import { AuctionStateTypes } from "../../../../../enums/AuctionStateTypes";
import InstagramGray from "../../../../Images/InstagramGray";

interface AuctionPropTypes {
  auction: Auction | ScheduledAuction;
  keyVal: string;
}

const IMGIX_PLACEHOLDER = `https://imgix.cosmicjs.com/5b7623c0-b5b1-11eb-bcb9-d7d3c90bc15f-PlaceholderImage.png`;
const AWAITING_PLACEHOLDER = `https://imgix.cosmicjs.com/24e36110-a7a0-11ec-ad74-ab832bebcff9-AwaitingConnection.png`;

const AuctionCard = ({ auction, keyVal }: AuctionPropTypes) => {
  const viewType = useSelector((state) => getSelectedViewType(state));

  const handleClick = (e) => {
    e.preventDefault();
    navigate(`/dashboard/auctions/${auction.id}/`);
  };

  const getDimension = () => {
    if (!isMobile.any() || viewType === ViewTypes.FullCardView) {
      return 400;
    }
    if (viewType === ViewTypes.MultipleCardView) {
      return 177;
    }
    return 82;
  };

  const pluralize = () => (auction.bids_summary?.bin_count > 1 ? "s" : "");

  const getBidandBinCount = () => {
    const innerText =
      auction && auction?.bids_summary
        ? `${auction.bids_summary?.bid_count}`
        : `0`;
    const outerText = ` bid${
      auction?.bids_summary?.bid_count > 1 ||
      auction.bids_summary?.bid_count === 0
        ? "s"
        : ""
    }${
      auction.bids_summary?.bin_count > 0
        ? ` & ${auction.bids_summary?.bin_count} BIN${pluralize()}`
        : ""
    }`;
    return ` (${innerText}${outerText})`;
  };

  const getCurrentBid = () =>
    auction && auction?.bids_summary && auction?.bids_summary?.winning_bid
      ? auction.bids_summary?.winning_bid.amount
      : auction?.bids_summary?.current_bid?.amount ||
        auction?.bids_summary?.current_bin?.amount ||
        0;

  const bottomStats = () => (
    <div className={styles.bottomStatsContainer}>
      <div className={styles.endDate}>
        <p>
          End Date:{" "}
          {auction &&
            auction.ends_at &&
            format(new Date(auction.ends_at), "dd MMM yyyy")}
        </p>
      </div>
      <div className={styles.platform}>
        <p>{getCurrencyLocaleValue(auction.currency, getCurrentBid())}</p>
        <p>{getBidandBinCount()}</p>
        <InstagramGray />
      </div>
    </div>
  );

  const dateSpan = () => (
    <div className={styles.listViewTitleContainer}>
      <h3>{auction && auction.name}</h3>
    </div>
  );

  const statusText = () => {
    switch (getAuctionState(auction)) {
      case AuctionStateTypes.publish:
        return "Live";
      case AuctionStateTypes.confirm:
        return "Needs Action";
      case AuctionStateTypes.finish:
        return "Closed";
      case AuctionStateTypes.schedule:
        return "Upcoming";
      case AuctionStateTypes.error:
        return "Error";
      case AuctionStateTypes.await:
        return "Awaiting";
      default: {
        return "Draft";
      }
    }
  };

  const statusClass = () => {
    switch (getAuctionState(auction)) {
      case AuctionStateTypes.publish:
        return styles.live;
      case AuctionStateTypes.await:
        return styles.await;
      case AuctionStateTypes.confirm:
      case AuctionStateTypes.finish:
        return styles.closed;
      case AuctionStateTypes.schedule:
        return styles.comingSoon;
      case AuctionStateTypes.error:
        return styles.error;
      default:
        return styles.draft;
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={(e) => handleClick(e)}
      key={keyVal}
      className={cn([styles.auctionCard])}
      onClick={(e) => handleClick(e)}
    >
      <div className={styles.imageContainer}>
        <div className={cn([styles.statusMarker, statusClass()])}>
          {statusText()}
        </div>
        {auction && auction.item && auction.item.files.length > 0 && (
          <LazyLoad once height={getDimension()}>
            <Imgix
              src={`${auction.item.files[0].url}`}
              htmlAttributes={{
                alt: auction.item.files[0].data.filename,
                src: `${auction.item.files[0].url}?blur=500&px=4&auto=format`,
              }}
              width={1080}
              height={1080}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className="lazyload blur-up"
            />
          </LazyLoad>
        )}
        {(!auction || auction.item.files.length === 0) && (
          <LazyLoad once height={getDimension()}>
            <Imgix
              src={`${
                auction.progress === AuctionStateTypes.await
                  ? AWAITING_PLACEHOLDER
                  : IMGIX_PLACEHOLDER
              }?auto=format`}
              htmlAttributes={{
                alt: "Placeholder",
                src: `${
                  auction.progress === AuctionStateTypes.await
                    ? AWAITING_PLACEHOLDER
                    : IMGIX_PLACEHOLDER
                }?blur=500&px=4&auto=format`,
              }}
              width={1080}
              height={1080}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className="lazyload blur-up"
            />
          </LazyLoad>
        )}
        {dateSpan()}
      </div>
      {bottomStats()}
    </div>
  );
};

export default AuctionCard;
