import React, { useEffect } from "react";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import { AuthProvider } from "../../../components/Auth/auth";
import DashboardLayout from "../../../components/DashboardLayout";
import AuctionCategorySelectionList from "../../../components/PageComponents/Dashboard/Auctions/AuctionCategorySelectionList";
import {
  isLoading,
  selectAuctions,
  selectCurrentPageFilter,
  selectProgressFilter,
} from "../../../store/auctions/selectors";
import AuctionListSearchFilters from "../../../components/PageComponents/Dashboard/Auctions/AuctionList/AuctionListSearchFilters";
import AuctionListWithFilters from "../../../components/PageComponents/Dashboard/Auctions/AuctionList/AuctionListWithFilters";
import { isIndividualAuctionLoading } from "../../../store/individualAuction/selectors";
import FullScreenLoading from "../../../components/Loading/FullScreenLoading";
import { useAppDispatch } from "../../../store";
import { isStoreLoading } from "../../../store/stores/selectors";
import { getIsAuthLoading } from "../../../store/auth/selectors";
import {
  selectUserInfo,
  selectUserInfoLoading,
} from "../../../store/user/selectors";
import {
  AuctionResults,
  fetchAuctionCounts,
  fetchAuctions,
} from "../../../store/auctions/actions";
import { User } from "../../../types/User";
import {
  getUserInfo,
  getUserInstagramAccounts,
} from "../../../store/user/actions";
import TablePagination from "../../../components/Pagination/TablePagination";
import {
  setCurrentPage,
  setProgressFilter,
} from "../../../store/auctions/reducer";
import { setPath } from "../../../store/auctionModal/reducer";
import { PathTypes } from "../../../components/PageComponents/Dashboard/Auctions/NewAuctionCreation/Types/PathTypes";

const PAGE_SIZE = 30;
const AuctionsPage = () => {
  const dispatch = useAppDispatch();
  const loading = useSelector(
    (state) =>
      isLoading(state) ||
      isIndividualAuctionLoading(state) ||
      isStoreLoading(state) ||
      getIsAuthLoading(state) ||
      selectUserInfoLoading(state)
  );
  const auctions = useSelector((state) =>
    selectAuctions(state)
  ) as AuctionResults;
  const userInfo = useSelector((state) => selectUserInfo(state)) as User;
  const newProgress = useSelector((state) => selectProgressFilter(state));
  const currentPage = useSelector((state) => selectCurrentPageFilter(state));

  const fetchAuctionsWithParams = (
    page,
    progress,
    page_size,
    allowSetFilter = false
  ) => {
    if (userInfo && userInfo.data) {
      const storeId = userInfo.data.last_store_id;
      if (allowSetFilter) {
        dispatch(setProgressFilter(progress || "all"));
        dispatch(setCurrentPage(page));
      }
      dispatch(
        fetchAuctions({
          id: storeId,
          page_size: page_size || PAGE_SIZE,
          sort_order: "desc",
          sort: "inserted_at",
          page: page || 1,
          progress: progress || "all",
        })
      );
      navigate(
        `?progress=${progress}&page=${page || 1}&page_size=${
          page_size || PAGE_SIZE
        }`
      );
    }
  };

  useEffect(() => {
    if (userInfo) {
      // set query params
      const storeId = userInfo.data.last_store_id;
      const queryParams = queryString.parse(window.location.search);
      const { page, progress, page_size }: any = queryParams;
      fetchAuctionsWithParams(page, progress, page_size || 10, true);
      dispatch(fetchAuctionCounts({ storeId }));
    }
  }, [userInfo]);

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getUserInstagramAccounts());
    dispatch(setPath(PathTypes.AUCTIONS));
  }, []);

  useEffect(() => {
    if (newProgress) {
      // set progress to the url
      const queryParams = queryString.parse(window.location.search);
      const { page, page_size }: any = queryParams;
      fetchAuctionsWithParams(page, newProgress, page_size || PAGE_SIZE);
    }
  }, [newProgress]);

  useEffect(() => {
    if (currentPage) {
      // set progress to the url
      const queryParams = queryString.parse(window.location.search);
      const { page_size, progress }: any = queryParams;
      fetchAuctionsWithParams(currentPage, progress, page_size || PAGE_SIZE);
    }
  }, [currentPage]);

  const updatePage = (newPage) => {
    // get url query params
    if (newPage) {
      dispatch(setCurrentPage(newPage));
    }
  };

  return (
    <AuthProvider>
      <DashboardLayout title="Bidddy - Auctions List" hideFooter>
        <div className="flex flex-col w-full overflow-x-hidden mb-8">
          <AuctionListSearchFilters />
          {!loading && <AuctionCategorySelectionList />}
          <AuctionListWithFilters />
          {auctions && auctions.meta && !loading && (
            <TablePagination
              count={auctions.meta.total_pages}
              currentPage={auctions.meta.page}
              setCurrentPage={(newPage) => updatePage(newPage)}
            />
          )}
        </div>
      </DashboardLayout>
      {loading && <FullScreenLoading />}
    </AuthProvider>
  );
};

export default AuctionsPage;
